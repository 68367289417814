<template>
    <layout :classes="classes" :routeName="routeName" :navigation="navigation" :sidebar="sidebar" :scope="scope" :container="false" :in_modal="in_modal">
        <div id="sticky-heading-container" :class="['heading-container sticky']" ref="sticky_heading_container">
            <h2 class="heading">{{$t('retirement_calculator.title')}}</h2>

            <div v-if="!in_modal" class="cta-container responsive desktop">
                <button v-if="calculation_result" class="cta cta-primary cta-slim" :class="{ loading: loading }" @click="calculate()">{{$t('retirement_calculator.card.calculation.resubmit')}}</button>
                <button v-else class="cta cta-primary cta-slim" :class="{ loading: loading, transparent: !data.situation }" @click="calculate()">{{$t('retirement_calculator.card.calculation.submit')}}</button>
            </div>

            <div v-if="!in_modal && data.situation" class="cta-container responsive mobile">
                <button v-if="calculation_result" class="cta cta-primary cta-slim wrap" :class="{ loading: loading }" @click="calculate()">{{$t('retirement_calculator.card.calculation.resubmit')}}</button>
                <button v-else class="cta cta-primary cta-slim wrap" :class="{ loading: loading }" @click="calculate()">{{$t('retirement_calculator.card.calculation.submit')}}</button>
            </div>
        </div>

        <div id="with-sticky-header" :class="['row top-row retirement-container with-sticky-header', {'extra-padding': !in_modal && data.situation}]">
            <div class="left">
                <div class="card calculation">
                    <div class="card-body"> 
                        <div class="question-wrapper">
                            <div class="question situation row">
                                <div class="label-container">
                                    <p>{{$t('retirement_calculator.card.calculation.situation.title')}}</p>
                                    <FormError :data="validation.situation"/>
                                </div>
                                
                                <multiselect    v-model="data.situation"
                                                :options="situationOptions"
                                                :close-on-select="true"
                                                :multiple="false"
                                                :allowEmpty="false"
                                                :searchable="false"
                                                :class="error('situation')"
                                                
                                                :placeholder="$t('retirement_calculator.card.calculation.situation.choices.placeholder')"
                                                :select-label="$t('language.multiselect.label.select')"
                                                :selected-label="$t('language.multiselect.label.selected')"
                                                :deselect-label="$t('language.multiselect.label.deselect')"
                                                :custom-label="translateOptions">
                                                
                                                <template slot="noResult">{{$t('language.multiselect.label.no_result')}}</template>
                                                <template slot="noOptions">{{$t('language.multiselect.label.no_options')}}</template>
                                </multiselect>
                            </div>

                            <div v-if="data.situation">
                                <div class="section-separator" />
                                <div :class="['question age']">
                                    <div class="label-container">
                                        <p>{{$t('retirement_calculator.card.calculation.age.title')}}</p>
                                        <p class="subtitle">{{$t('retirement_calculator.card.calculation.age.subtile')}}</p>
                                        
                                        <FormError :data="validation.age"/>
                                    </div>

                                    <div class="input-container">
                                        <div class="input-label-container">
                                            <span>{{$t('retirement_calculator.card.calculation.for_you')}}</span>
                                            <the-mask id="input-age-single" :class="['input input-text', error('age', 'you')]" :mask="['##']" v-model.number="data.you.age" @keyup.native.enter="calculate()" type="text" pattern="[0-9]*" />
                                        </div>

                                        <div v-if="data.situation === 'couple'" class="input-label-container">
                                            <span>{{$t('retirement_calculator.card.calculation.for_your_spouse')}}</span>
                                            <the-mask :class="['input input-text', error('age', 'spouse')]" :mask="['##']" v-model.number="data.spouse.age" @keyup.native.enter="calculate()" type="text" pattern="[0-9]*" />
                                        </div>
                                    </div>
                                </div>

                                <div class="section-separator" />

                                <div class="question retirement_revenue">
                                    <div class="label-container">
                                        <p>{{$t('retirement_calculator.card.calculation.retirement_revenue.title')}}</p>
                                        <p class="subtitle">{{$t('retirement_calculator.card.calculation.retirement_revenue.subtitle')}}</p>
                                        <FormError :data="validation.retirement_revenue"/>
                                    </div>

                                    <div class="input-container">
                                        <div class="input-label-container">
                                            <span>{{$t('retirement_calculator.card.calculation.for_you')}}</span>
                                            <the-mask id="input-retirement-revenue-single" :class="['input input-text', error('retirement_revenue', 'you')]" :mask="['# $', '## $', '### $', '#,### $', '##,### $', '###,### $' ]" v-model.number="data.you.retirement_revenue" @keyup.native.enter="calculate()" type="text" pattern="[0-9]*" />
                                        </div>

                                        <div v-if="data.situation === 'couple'" class="input-label-container">
                                            <span>{{$t('retirement_calculator.card.calculation.for_your_spouse')}}</span>
                                            <the-mask :class="['input input-text', error('retirement_revenue', 'spouse')]" :mask="['# $', '## $', '### $', '#,### $', '##,### $', '###,### $' ]" v-model.number="data.spouse.retirement_revenue" @keyup.native.enter="calculate()" type="text" pattern="[0-9]*" />
                                        </div>
                                    </div>
                                </div>

                                <div class="section-separator" />
                                
                                <div class="question rrq">
                                    <div class="label-container">
                                        <p>{{$t('retirement_calculator.card.calculation.rrq.title')}}</p>
                                        <p v-if="data.you.age >= 60 && data.you.age <= 69" class="subtitle">{{$t(`retirement_calculator.card.calculation.rrq.subtitle.${data.you.age}`, { pronoun: $t('retirement_calculator.card.calculation.rrq.pronouns.you')})}}</p>
                                        <p v-else-if="data.you.age >= 60" class="subtitle">{{$t(`retirement_calculator.card.calculation.rrq.subtitle.69`, { pronoun: $t('retirement_calculator.card.calculation.rrq.pronouns.you')})}}</p>
                                        <p v-else class="subtitle">{{$t(`retirement_calculator.card.calculation.rrq.subtitle.none.you`)}}</p>

                                        <p v-if="data.situation === 'couple' && data.spouse.age >= 60 && data.spouse.age <= 69" class="subtitle double">{{$t(`retirement_calculator.card.calculation.rrq.subtitle.${data.spouse.age}`, { pronoun: $t('retirement_calculator.card.calculation.rrq.pronouns.spouse')})}}</p>
                                        <p v-else-if="data.situation === 'couple' && data.spouse.age >= 60" class="subtitle double">{{$t(`retirement_calculator.card.calculation.rrq.subtitle.69`, { pronoun: $t('retirement_calculator.card.calculation.rrq.pronouns.spouse')})}}</p>
                                        <p v-else-if="data.situation === 'couple'" class="subtitle double">{{$t(`retirement_calculator.card.calculation.rrq.subtitle.none.spouse`)}}</p>

                                        <FormError :data="validation.rrq.you" />
                                        <FormError v-if="data.situation === 'couple'" :data="validation.rrq.spouse"/>
                                    </div>

                                    <div class="input-container" v-if="data.you.age >= 60 || data.spouse.age >= 60">
                                        <div v-if="data.you.age >= 60" class="input-label-container">
                                            <span>{{$t('retirement_calculator.card.calculation.for_you')}}</span>
                                            <the-mask id="input-rrq-single" :class="['input input-text', error('rrq', 'you')]" :mask="['# $', '## $', '### $', '#,### $', '##,### $' ]" v-model.number="data.you.rrq" @keyup.native.enter="calculate()" type="text" pattern="[0-9]*" />
                                        </div>

                                        <div v-if="data.situation === 'couple' && data.spouse.age >= 60" class="input-label-container">
                                            <span>{{$t('retirement_calculator.card.calculation.for_your_spouse')}}</span>
                                            <the-mask :class="['input input-text', error('rrq', 'spouse')]" :mask="['# $', '## $', '### $', '#,### $', '##,### $' ]" v-model.number="data.spouse.rrq" @keyup.native.enter="calculate()" type="text" pattern="[0-9]*" />
                                        </div>
                                    </div>
                                </div>

                                <div class="section-separator" />

                                <div class="question work_revenue">
                                    <div class="row">
                                        <div class="label-container">
                                            <p>{{$t('retirement_calculator.card.calculation.work_revenue.title')}}</p>
                                            <p class="subtitle">{{$t('retirement_calculator.card.calculation.work_revenue.subtitle')}}</p>
                                            <FormError :data="validation.work_revenue"/>
                                        </div>

                                        <div class="input-container">
                                            <div class="input-label-container">
                                                <span>{{$t('retirement_calculator.card.calculation.for_you')}}</span>
                                                <the-mask id="input-work-revenue-single" :class="['input input-text', error('work_revenue', 'you')]" :mask="['# $', '## $', '### $', '#,### $', '##,### $', '###,### $' ]" v-model.number="data.you.work_revenue" @keyup.native.enter="calculate()" type="text" pattern="[0-9]*" />
                                            </div>

                                            <div v-if="data.situation === 'couple'" class="input-label-container">
                                                <span>{{$t('retirement_calculator.card.calculation.for_your_spouse')}}</span>
                                                <the-mask :class="['input input-text', error('work_revenue', 'spouse')]" :mask="['# $', '## $', '### $', '#,### $', '##,### $', '###,### $' ]" v-model.number="data.spouse.work_revenue" @keyup.native.enter="calculate()" type="text" pattern="[0-9]*" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="suggestions">
                                        <p class="title">{{ $t('retirement_calculator.card.calculation.suggestions.title') }}</p>   

                                        <div class="items">
                                            <div class="item" v-for="(key, index) in suggestion_keys" :key="index">
                                                <div class="row">
                                                    <span class="desc">{{ $t(`retirement_calculator.card.calculation.suggestions.items.${key}.desc`) }}</span>
                                                    <span class="amount">{{ $t(`retirement_calculator.card.calculation.suggestions.items.${key}.amount`) }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="data.situation && in_modal" class="card-footer">
                        <span class="loading_message" v-if="loading">{{$t('retirement_calculator.card.calculation.loading_message')}}</span>
                        <button id="submit-results" class="cta cta-primary cta-slim wrap" :class="{ loading: loading }" @click="calculate()">{{$t('retirement_calculator.card.calculation.submit')}}</button>
                    </div>
                </div>
                
                <div v-if="!in_modal && $screen.width > 1220" class="disclaimers">
                    <span class="disclaimer" v-html="$t('retirement_calculator.card.calculation.disclaimers.both')" />
                </div>
            </div>

            <div v-if="calculation_result" id="calculation_result" class="right">
                <div class="card result">
                    <div class="card-heading">
                        <p class="title">{{$t('retirement_calculator.card.results.title')}}</p>
                    </div>

                    <div class="card-body">
                        <div class="remaining">
                            <div class="row">
                                <span class="percentage">{{ calculation_result.revenue_remaining_percentage }}%</span>
                                <span class="text">{{$t('retirement_calculator.card.results.revenue_remaining_percentage')}}</span>
                            </div>
                            
                            <span class="equivalent" v-html="$t('retirement_calculator.card.results.revenue_remaining_value', { net: $utils._format_yearly_cash(calculation_result.revenue_remaining_value), gross: $utils._format_yearly_cash(data.you.work_revenue) } )"></span>
                        </div>
                        
                        <div class="result-item">
                            <p>{{$t('retirement_calculator.card.results.retirement')}}</p>
                            <span class="result">{{ $utils._format_yearly_cash(data.you.retirement_revenue) }}</span>
                        </div>

                        <div v-if="data.you.rrq && data.you.rrq !== ''" class="result-item">
                            <p>{{$t('retirement_calculator.card.results.rrq')}}</p>
                            <span class="result">{{ $utils._format_yearly_cash(data.you.rrq) }}</span>
                        </div>

                        <div class="result-item">
                            <p>{{$t('retirement_calculator.card.results.work_revenue')}}</p>
                            <span class="result">{{ $utils._format_yearly_cash(calculation_result.work_revenue) }}</span>
                        </div>

                        <div class="result-item">
                            <p>{{$t('retirement_calculator.card.results.remaining_revenue')}}</p>
                            <span class="result bold">{{ $utils._format_yearly_cash(calculation_result.revenue_remaining_value) }}</span>
                        </div>

                        <div class="chart-container">
                            <Bar
                                :chart-options="chart.chart_options"
                                :chart-data="chart.chart_data"
                                :height="chart.height" />
                        </div>

                        <button v-if="embedded" :class="['cta cta-slim cta-outline dark', { loading: loading_retirement_amount }]" @click="update()">{{$t('retirement_calculator.card.results.update', { revenu:  $utils._format_yearly_cash(data.you.work_revenue)})}}</button>
                        <button id="return-to-account-creation" v-else-if="in_modal" :class="['cta cta-slim cta-secondary dark']" @click="close_modal()">{{$t('retirement_calculator.card.results.close', { revenu:  $utils._format_yearly_cash(data.you.work_revenue)})}}</button>
                        <button v-else class="cta cta-slim cta-secondary wrap" @click="modal = true">{{$t('retirement_calculator.card.results.submit')}}</button>
                        
                        <div class="legend">
                            <p class="title">{{$t('retirement_calculator.chart.legend.title')}}</p>

                            <div class="first">
                                <span class="title">{{$t('retirement_calculator.chart.labels.work_revenue')}}</span>
                                <span class="desc" v-html="$t(`retirement_calculator.chart.legend.values.work_revenue.${data.situation}`)"></span>
                            </div>

                            <div>
                                <span class="title">{{$t('retirement_calculator.chart.labels.gov')}}</span>
                                <span class="desc" v-html="$t('retirement_calculator.chart.legend.values.gov')"></span>
                            </div>

                            <div>
                                <span class="title">{{$t('retirement_calculator.chart.labels.incentives')}}</span>
                                <span class="desc" v-html="$t('retirement_calculator.chart.legend.values.incentives')"></span>
                            </div>

                            <div>
                                <span class="title">{{$t('retirement_calculator.chart.labels.remaining')}}</span>
                                <span class="desc" v-html="$t('retirement_calculator.chart.legend.values.remaining')"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="in_modal || $screen.width <= 1220" class="disclaimers">
                <span class="disclaimer" v-html="$t('retirement_calculator.card.calculation.disclaimers.both')" />
            </div>

            <button id="retirement-calculator-modal-close" v-if="in_modal" :class="['cta cta-slim cta-outline dark max-width']" @click="cancel()">{{$t('retirement_calculator.card.results.cancel', { revenu:  $utils._format_yearly_cash(data.you.work_revenue)})}}</button>
        </div>

        <div class="modal modal-retirement-calculator" :class="{ active: modal }">
            <div class="modal-card">
                <p class="title">{{$t('retirement_calculator.modal.title')}}</p>
                <p class="subtitle">{{$t('retirement_calculator.modal.subtitle')}}</p>
                
                <div class="cta-container">
                    <div class="cta cta-primary cta-slim" @click="sign_up()">{{$t('language.continue')}}</div>
                    <div class="cta cta-outline dark cta-slim" @click="modal = false">{{$t('language.cancel')}}</div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import cookies from 'js-cookie';
import Layout from '../../components/Layout.vue';
import FormError from '../../components/Utils/FormError.vue';
import { Bar } from 'vue-chartjs/legacy';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ChartDataLabels)

export default {
    name: "retirement-calculator",

    components: {
        Layout,
        FormError,
        Bar
    },

    props: {
        navigation: {
            type: String,
            default: 'simple'
        },

        sidebar: {
            type: Boolean,
            default: false
        },

        scope: {
            type: Object,
            default() {
                return {};
            }
        },
        
        embedded: {
            type: Boolean,
            default() {
                return null;
            }
        },

        in_modal: {
            type: Boolean,
            default() {
                return false;
            }
        }
    },

    watch: {
        'data.situation': function() {
            const self = this;
            if(self.data.situation === 'single') {
                self.data.spouse = {
                    age: '',
                    work_revenue: '',
                    retirement_revenue: '',
                    rrq: ''
                }
            }
        },

        'data.you.age': function() {
            const self = this;
            if(self.data.you.age < 60) {
                self.data.you.rrq = ''
            }
        },

        'data.spouse.age': function() {
            const self = this;
            if(self.data.spouse.age < 60) {
                self.data.spouse.rrq = ''
            }
        },

        in_modal: function(val) {
            const self = this;
            if(val) {
                self.classes = { 'retirement-calculator': true, }
            }
        },

        modal: function() {
            const self = this;
            if(self.modal) { self.$utils._open_modal(); }
            else { self.$utils._close_modal(); }
        }
    },

    data() {
        return {
            data: {
                situation: null,
                you: {
                    age: '',
                    work_revenue: '',
                    retirement_revenue: '',
                    rrq: ''
                },

                spouse: {
                    age: '',
                    work_revenue: '',
                    retirement_revenue: '',
                    rrq: ''
                }
            },

            rrq_limit: {
                60: 10711,
                61: 11794,
                62: 12877,
                63: 13960,
                64: 15043,
                65: 16307,
                66: 17570,
                67: 18834,
                68: 20098,
                69: 21361,
            },

            suggestion_keys: ['8_hours', '16_hours', '24_hours'],

            calculation_result: null,

            loading: false,
            form_validated: false,

            situationOptions: [ 'single', 'couple' ],

            validation: {
                situation: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                },

                age: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                },

                work_revenue: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                },

                retirement_revenue: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                },

                rrq: {
                    you: {
                        error: false,
                        key: 'errors.validation.fieldMandatory'
                    },

                    spouse: {
                        error: false,
                        key: 'errors.validation.fieldMandatory'
                    }
                }
            },

            routeName: "retirement-calculator",
            classes: {
                'retirement-calculator': true,
                dashboard: true,
            },

            modal: false,
            loading_retirement_amount: false,

            chart: {
                height: 200,
                chart_data: {
                    labels: [   this.$t('retirement_calculator.chart.labels.work_revenue'), 
                                this.$t('retirement_calculator.chart.labels.gov'), 
                                this.$t('retirement_calculator.chart.labels.incentives'), 
                                this.$t('retirement_calculator.chart.labels.remaining'), 
                    ],
                    datasets: [
                        {
                            label: '',
                            backgroundColor: ['#ffce1a', '#ffd94d', '#ffe480', '#ffefb3'],
                            data: [],
                            borderRadius: 4,
                            barPercentage: 0.6
                        },
                    ]
                },
                chart_options: {
                    responsive: true,
                    plugins: {
                        datalabels: {
                            align: 'end',
                            anchor: 'end',
                            offset: 0,

                            color: 'black',
                            font: { weight: 'bold' },

                            formatter: function(value) {
                                const as_string = value.toString().trim();
                                if(as_string.length === 4) {
                                    return as_string.slice(0, 1) + ',' + as_string.slice(1, 4) + ' $'
                                } else if(as_string.length === 5) {
                                    return as_string.slice(0, 2) + ',' + as_string.slice(2, 5) + ' $'
                                } else if (as_string.length === 6) {
                                    return as_string.slice(0, 3) + ',' + as_string.slice(3, 6) + ' $'
                                }else {
                                    return as_string + ' $'
                                }
                            }
                        }
                    }
                }
            }
        }
    },

    methods: {
        reset_validation: function() {
            const self = this;

            self.form_validated = true;
            Object.keys(self.validation).forEach(val => {
                if(val === 'rrq') {
                    ['you', 'spouse'].forEach(person => {
                        self.validation[val][person].error = false;
                        self.validation[val][person].key = 'errors.validation.fieldMandatory';
                    })
                }else {
                    self.validation[val].error = false;
                    self.validation[val].key = 'errors.validation.fieldMandatory';
                }
            });
        },

        set_error: function(key, person = null, age = null) {
            const self = this;

            if(key === 'rrq') { 
                const formatted_age = age >= 69 ? '69_and_more' : age;
                self.validation[key][person].error = true;

                if(age >= 60) {
                    self.validation[key][person].key = `errors.validation.retirement_calculator.${key}.${person}.${formatted_age}`
                }else {
                    self.validation[key][person].key = `errors.validation.retirement_calculator.${key}.${person}.empty`
                }
            }else {
                self.validation[key].error = true;
                self.validation[key].key = `errors.validation.retirement_calculator.${key}`;
            }
        },

        validate_mandatory_fields: function() {
            const self = this;

            self.reset_validation();
            
            const situations = self.data.situation === 'single' ? ['you'] : ['you', 'spouse'];
            situations.forEach(person => {
                Object.keys(self.data[person]).forEach(key => {
                    if(key === 'rrq') {
                        if(self.data[person].age >= 60) {
                            if(self.data[person].rrq === null || self.data[person].rrq === undefined || self.data[person].rrq === '') {
                                self.validation[key][person].error = true;
                            }
                        }
                    } else if(self.data[person][key] === null || self.data[person][key] === undefined || self.data[person][key] === '') {
                        self.validation[key].error = true;
                    }
                });
                
                if(self.data[person].age < 55 || self.data[person].age > 99)
                    self.set_error('age');

                if(self.data[person].work_revenue < 0 || self.data[person].work_revenue > 250000) 
                    self.set_error('work_revenue');

                if(self.data[person].retirement_revenue < 0 || self.data[person].retirement_revenue > 250000)
                    self.set_error('retirement_revenue');

                if(self.data[person].age >= 60) {
                    if(self.data[person].rrq < 0 || self.data[person].rrq > self.rrq_limit[self.data[person].age]){
                        self.set_error('rrq', person, self.data[person].age);
                    }
                }else if (self.data[person].age < 60) {
                    if(!(self.data[person].rrq === null || self.data[person].rrq === undefined || self.data[person].rrq === '' || self.data[person].rrq === 0)) {
                        self.set_error('rrq', person, self.data[person].age);
                    }
                }
            })

            Object.keys(self.validation).forEach(key => {
                if(key === 'rrq') {
                    ['you', 'spouse'].forEach(person => {
                        if(self.validation[key][person].error) {
                            self.form_validated = false;
                        }
                    })
                }else {
                    if(self.validation[key].error) {
                        self.form_validated = false;
                    }
                }
                
            });
        },

        calculate: function() {
            const self = this;

            self.validate_mandatory_fields();
            if(self.form_validated) {
                self.loading = true;

                let data = [];
                data.push({ 
                    key: "you", 
                    age: self.data.you.age,  
                    work_revenue: self.data.you.work_revenue, 
                    retirement_revenue: self.data.you.retirement_revenue, 
                    rrq: self.data.you.age >= 60 ? self.data.you.rrq : 0
                })

                if(self.data.situation === 'couple') {
                    data.push({ 
                        key: "spouse", 
                        age: self.data.spouse.age,  
                        work_revenue: self.data.spouse.work_revenue, 
                        retirement_revenue: self.data.spouse.retirement_revenue, 
                        rrq: self.data.spouse.age >= 60 ? self.data.spouse.rrq : 0
                    })
                }

                const instance = self.$axios.create({
                    baseURL: process.env.VUE_APP_RETIREMENT_PROXY_API_ENDPOINT,
                    headers: { 'x-api-key': process.env.VUE_APP_RETIREMENT_PROXY_API_KEY }
                })
                
                instance.post('proxy', data)
                    .then(response => {
                        if(response.status == 200) {
                            self.loading = false;
                            self.calculation_result = response.data;
                            self.chart.chart_data.datasets[0].data = [
                                self.calculation_result.work_revenue,
                                self.calculation_result.gouvernement_withholding,
                                self.calculation_result.incentives_total,
                                self.calculation_result.revenue_remaining_value,
                            ]

                            if(self.in_modal || self.$screen.width <= 1220) {
                                self.$nextTick(() => {
                                    //const height = self.$refs.sticky_heading_container.clientHeight
                                    //const options = { element: '#calculation_result', easing: 'ease-in', offset: height }
                                    //self.$scrollTo(options)

                                    document.getElementById('calculation_result').scrollIntoView({behavior: 'smooth'})
                                })
                            }
                        }
                    })
                    .catch(error => {
                        self.loading = false;
                        if(error && error.response) {
                            if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                        } else { console.log(error); }
                    });

            } else {
                self.$toasted.error(
                    self.$t('notifications.allFieldsAreMandatory'),
                    { icon: 'circle-exclamation' }
                );
            }
        },

        translateOptions: function(key) {
            const self = this;
            return self.$t(`retirement_calculator.card.calculation.situation.choices.${key}`)
        },

        sign_up: function() {
            const self = this;

            cookies.remove('amount');
            cookies.remove('from_retirement_calculator');
            
            cookies.set('amount', self.data.you.work_revenue, { expires: 7, secure: true, sameSite: 'strict' });
            cookies.set('from_retirement_calculator', true, { expires: 7, secure: true, sameSite: 'strict' });
            
            self.$utils._navigate_to_name('signup');
        },

        close_modal: function() {
            const self = this;
            const emit_data = { amount: self.data.you.work_revenue };
            self.reset_modal();

            self.$emit('close_modal', emit_data)
        },

        reset_modal: function() {
            const self = this;
            self.calculation_result = null;
            self.data = {
                situation: null,
                you: {
                    age: '',
                    work_revenue: '',
                    retirement_revenue: '',
                    rrq: ''
                },

                spouse: {
                    age: '',
                    work_revenue: '',
                    retirement_revenue: '',
                    rrq: ''
                }
            }
        },

        cancel: function() {
            const self = this;
            self.reset_modal();

            self.$emit('cancel')
        },

        update: function() {
            const self = this;

            self.loading_retirement_amount = true;
            self.$axios
                .put('person/retirement', { retirement_amount: self.data.you.work_revenue })
                .then(response => {
                    self.loading_retirement_amount = false;
                    if(response.status == 200){
                        self.$utils._navigate_to_name('worker-preferences')
                    }
                })
                .catch(error => {
                    self.loading_retirement_amount = false;
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }
                });
        },

        error: function(key, person = null) {
            const self = this;

            try {
                if(!person) {
                    return { error: self.validation[key].error }
                } else if(key === 'rrq') {
                    return { error: self.validation[key][person].error }
                } else {
                    if(self.validation[key].error) {
                        if(self.data[person][key] === null || self.data[person][key] === undefined || self.data[person][key] === '') {
                            return { error: true }
                        } else if(key === 'age') {
                            if(self.data[person][key] < 55 || self.data[person][key] > 99) {
                                return { error: true }
                            }
                        } else if(key === 'work_revenue' || key === 'retirement_revenue') {
                            if(self.data[person][key] < 0 || self.data[person][key] > 250000) {
                                return { error: true }
                            }
                        }
                    }
                }
            }catch (e) {
                console.log(`${this.$options.name}: validation error for ${key}`)
            }
        }
    },

    mounted() {
        const self = this;
        if(self.in_modal) {
            self.classes = { 'retirement-calculator': true }
        }else if (self.embedded) {
            self.classes = {
                'retirement-calculator': true,
                dashboard: true,
                embedded: true
            }
        }
    }
}
</script>